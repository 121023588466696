import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { Box, Flex } from '@latitude/box';
import { FeatureTiles } from '@latitude/feature-tiles';
import { Heading4, Heading5 } from '@latitude/heading';
import { Lframe } from '@latitude/lframe';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  FLEX_WRAP,
  FONT_SIZE,
  JUSTIFY_CONTENT,
  MARGIN,
  PADDING
} from '@latitude/core/utils/constants';

import HeroBranded from '../../components/HeroBranded/HeroBranded';
import Layout from '../../components/layout';
import StickyNavigationBranded from '../../components/StickyNavigation/StickyNavigationBranded';
import PageData from './data/our-company.json';
import TeamData from './data/_our-team';
import imageHero from './images/lshape-hero.png';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { FONT_WEIGHT } from '@/utils/constants';
import imageCreateTomorrow from '../../images/values-create-tomorrow.svg';
import imagePursueExcellence from '../../images/values-pursue-excellence.svg';
import imageTakeOwnership from '../../images/values-take-ownership.svg';
import imageWinTogether from '../../images/values-win-together.svg';

const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -30px;
`;

const PlayerWrapper = styled.div`
  position: relative;
  margin: auto;
  padding-top: 170px;
  width: 300px;
  
  @media (min-width: ${BREAKPOINT.SM}) {
    padding-top: 285px;
    width: 500px;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 400px;
    width: 700px;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const OurCompanyPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/about-us/our-company/"
          />
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe bgImage={imageHero} />
            <HeroBranded
              className="bg-lightest"
              title={PageData.hero.title}
              text={PageData.hero.text}
              breadcrumbs={PageData.hero.breadcrumbs}
              css={`
                @media (min-width: ${BREAKPOINT.MD}) {
                  .HeroTitle {
                    padding-top: 80px;
                  }
                  .HeroText {
                    padding-bottom: 30px;
                  }
                }
              `}
            />
          </>
        )}
        
        <StickyNavigationBranded
          items={PageData.stickyNavigation}
          trackId="our-company-stickynav"
          {...state?.inPageNavData?.[0]}
        />

        <Anchor id="about-us" />
        <OurStorySection />

        <Anchor id="our-values" />
        <OurValues />

        <Section css={`background-color: #F8F8F8; padding: 40px 0;`}>
          <PlayerWrapper>
            <ReactPlayer
              url={'https://youtu.be/tZ0HehBpYas'}
              className="react-player"
              width="100%"
              height="100%"
              controls
            />
          </PlayerWrapper>
        </Section>

        <Anchor id="leadership-team" />
        <FeatureTiles
          bgColor={COLOR.WHITE}
          useLocalAssets
          heading="Our leadership team"
          tiles={TeamData}
        />
        <MediaEnquiry />
      </main>
    </Layout>
  );
};

const OurStorySection = () => {
  return (
    <Section className="bg-lightest" css={`background-color: #F8F8F8`}>
      <div className="row justify-content-center">
        <div className="col-md-10 text-left">
          <Vertical spacing={MARGIN.TEXT}>
            <Heading4
              color={COLOR.BLACK}
              align={ALIGN.CENTER}
              marginTop={MARGIN.M48}
            >
              We make it possible 
            </Heading4>
            <Heading5
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              fontWeight={FONT_WEIGHT.NORMAL}
            >
              With financing solutions in the moments that matter 
            </Heading5>

            <Text
              fontSize={FONT_SIZE.NORMAL}
              marginTop={MARGIN.M32}
              align={ALIGN.LEFT}
            >
              Latitude is there for our customers through all life's moments - the big, the small, the planned and the unexpected. For now and in the future.
              <br />
              <br />
              We're the brand that helps customers shop and purchase what they need with credit cards, interest-free payment plans and personal loans. We deliver much-needed competition to the major banks and choice to consumers. 
              <br />
              <br />
              We help retailers attract customers and grow sales with seamless payment and lending solutions, fast decisions, and valuable insights and sales opportunities with our millions of customers.
              <br />
              <br />
              Our products are trusted by top brands like Harvey Norman, JB Hi-Fi, The Good Guys, Amart, The Warehouse Group, Samsung, and Apple across Australia and New Zealand. Plus, we partner with 5,500+ brokers to connect customers with the right personal loans, whether for a home renovation, a new car, or a major life purchase.
              <br />
              <br />
              We're passionate about empowering people to unlock opportunities and reach their goals. By pushing the limits of what’s possible, we inspire others to imagine and create new possibilities.
            </Text>
            
            <Heading4
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              marginTop={MARGIN.M40}
            >
              Our Story
            </Heading4>
            <Text
              fontSize={FONT_SIZE.NORMAL}
              marginTop={MARGIN.M32}
              align={ALIGN.LEFT}
            >
              Latitude has a long history of over 100 years dating back to the 1920s when Australian Guarantee Corporation ('AGC'), one of Latitude's predecessor businesses, was founded to provide finance for the purchase of household items. Latitude's history traces back to a range of acquisitions made by GE in Australia and New Zealand, including Nissan Finance, AVCO Financial (including Hallmark Insurance) and AGC.
              <br />
              <br />
              In 2015, backed by Värde Partners, KKR and Deutsche Bank, we transitioned from GE into what we are today — a leading consumer lender called Latitude.
              <br />
              <br />
              In April 2021, Latitude listed on the Australian Stock Exchange. In late 2021, Latitude acquired Symple Loans, a Melbourne-based personal lending fintech.
            </Text>
          </Vertical>
        </div>
      </div>
    </Section>
  );
};

const OurValues = () => {
  const ImageGroup = ({ imgSrc, text }) => (
    <div css={`
        text-align: center;
        @media (min-width: ${BREAKPOINT.LG}) {
          max-width: 20%;
        }
      `}
      >
      <img src={imgSrc} alt="take-ownership" />
      <p css="font-size: 16px;">
        {text}
      </p>
    </div>
  )

  return (
    <Section 
      id="our-values"  
      css={`background-color: #F8F8F8; padding: 60px 0;`}
    >
      <div className="row justify-content-center">
        <div className="col-md-10 text-center">
          <Heading4
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginTop={MARGIN.M24}
            marginBottom={MARGIN.M8}
          >
            Our values
          </Heading4>
          <Text
            align={ALIGN.LEFT}
            color={COLOR.BLACK}
            marginTop={MARGIN.M32}
            marginBottom={MARGIN.M24}
          >
            Our values guide our actions and decisions, ensuring we deliver the best outcomes ​for our customers, partners, each other and for Latitude.
          </Text>
        </div>
      </div>
      <div css={`
        display: flex;
        justify-content: space-evenly;
        @media (max-width: ${BREAKPOINT.LG}) {
          flex-direction: column;
          align-items: center;
        }
      `}>
        <ImageGroup 
          imgSrc={imageTakeOwnership}
          text="We step into challenges, execute with grit and take accountability."
        />
        <ImageGroup 
          imgSrc={imagePursueExcellence}
          text="We act with integrity to deliver high quality outcomes."
        />
        <ImageGroup 
          imgSrc={imageWinTogether}
          text="We seek different perspectives and unify as one company to win in the market."
        />
        <ImageGroup 
          imgSrc={imageCreateTomorrow}
          text="We think boldly and adapt with agility to innovate and shape the future."
        />
      </div>
    </Section>
  )
};

const MediaEnquiry = () => {
  return (
    <Flex
      backgroundColor={COLOR.BLUE_BABY}
      justifyContent={JUSTIFY_CONTENT.CENTER}
      padding={PADDING.P32}
      flexWrap={FLEX_WRAP.WRAP}
    >
      <Box
        paddingLeft={PADDING.P16}
        paddingRight={PADDING.P16}
        css={`
          text-align: ${ALIGN.RIGHT};
        `}
      >
        <SvgInline name="icon-mail" />
      </Box>
      <Box paddingLeft={PADDING.P16} paddingRight={PADDING.P16}>
        <Heading5
          color={COLOR.BLACK}
          marginTop={MARGIN.M16}
          marginBottom={MARGIN.M8}
          css={`
            @media (max-width: 521px) {
              text-align: center;
              font-size: 16px;
            }
          `}
        >
          For media and journalist enquiries only.
        </Heading5>
        <div
          css={`
            @media (max-width: 660px) {
              text-align: center;
            }
            @media (max-width: 521px) {
              font-size: 14px;
            }
          `}
        >
          <Link
            trackId="media-enquiries-email"
            trackEventData={{
              location: 'For media and journalist enquiries only.'
            }}
            className="link--blue text-link__exclude"
            href="mailto:media.enquiries@latitudefinancial.com"
          >
            media.enquiries@latitudefinancial.com
          </Link>
        </div>
      </Box>
    </Flex>
  );
};

export default OurCompanyPage;
